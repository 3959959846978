<template>
	<el-dialog
		:title="titleDialog"
		:visible="showDialog"
		@close="close"
		@open="create"
		class="certificate-form"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
	>
		<v-row dense>
			<v-col cols="12" xl="6" lg="12">
				<div class="form-group" :class="{ 'has-danger': errors.certificate }">
					<label class="control-label">Certificado pfx</label>
					<el-upload
						ref="upload"
						:headers="headers"
						:data="{
							password: form.password,
							certificate_date_of_issue: form.certificate_date_of_issue,
							certificate_date_of_due: form.certificate_date_of_due,
							date_of_alert: form.date_of_alert,
						}"
						:action="`${appUrlBackEnd}/api/certificates/upload`"
						:show-file-list="false"
						:auto-upload="false"
						:multiple="false"
						:on-error="errorUpload"
						:on-success="successUpload"
					>
						<el-button slot="trigger" type="primary">Selecciona un archivo</el-button>
					</el-upload>
					<small
						class="form-control-feedback"
						v-if="errors.certificate"
						v-text="errors.certificate[0]"
					></small>
				</div>
			</v-col>
			<v-col cols="12" xl="6" lg="12">
				<div class="form-group" :class="{ 'has-danger': errors.password }">
					<label class="control-label">Contraseña</label>
					<el-input v-model="form.password"></el-input>
					<small
						class="form-control-feedback"
						v-if="errors.password"
						v-text="errors.password[0]"
					></small>
				</div>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" xl="6" lg="12">
				<div class="form-group" :class="{ 'has-danger': errors.certificate_date_of_issue }">
					<label class="control-label">Válido desde:</label>
					<el-date-picker
						v-model="form.certificate_date_of_issue"
						type="date"
						value-format="yyyy-MM-dd"
						format="dd/MM/yyyy"
						:clearable="false"
						@change="changeDateOfIssue"
					></el-date-picker>
					<small
						class="form-control-feedback"
						v-if="errors.certificate_date_of_issue"
						v-text="errors.certificate_date_of_issue[0]"
					></small>
				</div>
			</v-col>
			<v-col cols="12" xl="6" lg="12">
				<div class="form-group" :class="{ 'has-danger': errors.date_of_due }">
					<label class="control-label">Válido hasta:</label>
					<el-date-picker
						v-model="form.certificate_date_of_due"
						type="date"
						value-format="yyyy-MM-dd"
						format="dd/MM/yyyy"
						:clearable="false"
					></el-date-picker>
					<small
						class="form-control-feedback"
						v-if="errors.certificate_date_of_due"
						v-text="errors.certificate_date_of_due[0]"
					></small>
				</div>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" xl="6" lg="12">
				<div class="form-group" :class="{ 'has-danger': errors.date_of_due }">
					<label class="control-label">Mostrar alerta desde:</label>
					<el-date-picker
						v-model="form.date_of_alert"
						type="date"
						value-format="yyyy-MM-dd"
						format="dd/MM/yyyy"
						:picker-options="pickerOptionsAlert"
						:clearable="false"
					></el-date-picker>
					<small
						class="form-control-feedback"
						v-if="errors.date_of_alert"
						v-text="errors.date_of_alert[0]"
					></small>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xl="12">
				<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
				<el-button type="primary" @click.prevent="clickUpload" :loading="loading_submit">
					<template v-if="loading_submit">
						Procesando...
					</template>
					<template v-else>
						Procesar
					</template>
				</el-button>
			</v-col>
		</v-row>
	</el-dialog>
</template>

<script>
import AppConfig from 'Constants/AppConfig';
import { confirm } from 'Mixins/confirm';
import dayjs from 'dayjs';

export default {
	props: ['showDialog', 'recordId'],
	mixins: [confirm],
	data() {
		return {
			appUrlBackEnd: AppConfig.appUrlBackEnd,
			loading_submit: false,
			headers: {
				Authorization: null,
			},
			titleDialog: null,
			resource: 'certificates',
			errors: {},
			form: {},
			pickerOptionsAlert: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return this.form.certificate_date_of_issue > time;
				},
			},
		};
	},
	created() {},
	methods: {
		initForm() {

			const accessToken = document.cookie.replace(
				/(?:(?:^|.*;\s*)access_token=\s*([^;]*).*$)|^.*$/,
				'$1'
			);

			this.errors = {};
			this.form = {
				id: null,
				certificate: null,
				password: null,
				certificate_date_of_issue: dayjs().format('YYYY-MM-DD'),
				certificate_date_of_due: dayjs()
					.add(1, 'year')
					.format('YYYY-MM-DD'),
				date_of_alert: dayjs()
					.add(11, 'month')
					.format('YYYY-MM-DD'),
			};

			this.headers.Authorization = `Bearer ${accessToken}`;
		},
		async create() {
			this.titleDialog = 'Generar Certificado PEM';
			await this.initForm();
		},
		changeDateOfIssue() {
			this.form.certificate_date_of_due = dayjs(this.form.certificate_date_of_issue)
				.add(1, 'year')
				.format('YYYY-MM-DD');
			this.form.date_of_alert = dayjs(this.form.certificate_date_of_due)
				.subtract(1, 'month')
				.format('YYYY-MM-DD');
		},
		clickUpload() {
			this.confirm().then(() => {
				this.$refs.upload.submit();
			});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
		successUpload(response) {
			if (response.success) {
				this.$message.success(response.message);
				this.$eventHub.$emit('reloadData', this.resource);
				this.close();
			} else {
				this.$message({ message: response.message, type: 'error' });
			}
		},
		errorUpload(response) {
			console.log(response);
		},
	},
};
</script>
